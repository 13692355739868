@import '../../util/constants.scss';

$closeButtonSize: 1.25rem;

.thumbnails {
  padding: 15px 0;
  display: flex;
  flex-direction: row;

  &:empty {
    display: none;
  }

  &__thumbnail {
    margin-top: $closeButtonSize / 2;

    &,
    &__img {
      height: 80px;
      width: 80px;
      position: relative;
    }

    &__img {
      border-radius: 8px;
      overflow: hidden;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }

  &__thumbnail + &__thumbnail {
    margin-left: 20px;
  }

  &__close {
    position: absolute;
    right: ($closeButtonSize / 2) * -1;
    top: ($closeButtonSize / 2) * -1;
    width: $closeButtonSize;
    height: $closeButtonSize;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    z-index: 2;
  }
}

@mixin faded-out {
  opacity: 0;
  transition: visibility 0s linear $transition-time,
    opacity $transition-time $transition-ease;
  visibility: hidden;
}

@mixin faded-in {
  opacity: 1;
  transition: visibility 0s linear 0s, opacity $transition-time $transition-ease;
  visibility: visible;
}

@mixin icon($color: $grey-dark, $size: 1.5rem, $vAlign: middle) {
  fill: $color;
  height: $size;
  width: $size;
  vertical-align: $vAlign;
}

@mixin shimmerAnimation($duration: 1s, $width: 400px, $height: 50px) {
  background-color: darken($white100, 5%);
  animation-duration: $duration;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  background-image: linear-gradient(
    to right,
    darken($white100, 5%) 8%,
    darken($white100, 10%) 18%,
    darken($white100, 5%) 33%
  );
  background-size: $width $height;

  @keyframes placeholderShimmer {
    0% {
      background-position: -$width/2 0;
    }

    100% {
      background-position: $width/2 0;
    }
  }
}

@mixin visuallyHidden {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

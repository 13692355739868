$textarea-height: var(--medkit-textarea-height, 10rem);

.Textarea {
  &__textarea {
    height: $textarea-height;
    padding-top: 0.5rem;

    &_withLabel {
      padding-top: 1.5rem;
    }
  }
}

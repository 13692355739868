@import '@babylon/design-tokens/all.scss';

// design tokens
$borderColor: $purple100;
$backgroundColor: $lightgray;
$fontColor: $label-color;
$focusedColor: $purple100;
$acceptColor: $purple100;
$rejectColor: $pink100;

.baseStyle {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-width: 2px;
  border-radius: 10px;
  border-color: $borderColor;
  border-style: dashed;
  background-color: $backgroundColor;
  color: $fontColor;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.focusedStyle {
  border-color: $focusedColor;
}

.rejectStyle {
  border-color: $rejectColor;
}

.acceptStyle {
  border-color: $acceptColor;
}

.successStyle {
  border-color: $green100;
  border-style: solid;
}

.errorStyle {
  border-color: $rejectColor;
  border-style: solid;
}

.activeStyle {
  background-color: $grey25;
}

.UploadLinkButton {
  margin-left: 4px;

  &__confirmButton {
    font-weight: bold;
  }
}

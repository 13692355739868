@import '../../design-tokens/all.scss';

$font-family: 'Visuelt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$font: var(--medkit-font, $font-family);
$base-font-size: var(--medkit-base-font-size, rem-calc(16));

// Overriding the value from medkit tokens until it is updated by the
// design-system squad. See thread: https://babylonhealth.slack.com/archives/CBD8SEXHB/p1572347957057400
$traffic-light-risk-red-500: #d53232;
$traffic-light-positive-green-400: #008535;
